<template>
  <div>
    <div class="poinTop">
      <div class="order-nav">
        <ul>
          <li :class="activeName == 'first' ? 'active' : ''" @click="clickState('first')">全部記錄</li>
          <li :class="activeName == 'second' ? 'active' : ''" @click="clickState('second')">獲得記錄</li>
          <li :class="activeName == 'third' ? 'active' : ''" @click="clickState('third')">使用記錄</li>
        </ul>
      </div>
      <div class="top">
        <div style="display: flex;align-items: center;gap: 10px">
        <img style="cursor: pointer" src="@/assets/image/dawen.png" alt="wen" @click="dialogTableVisible = true" />
         <div class="card">
            <span>可折抵eSIM Coin</span>
            <span>1000</span>
         </div>
        </div>
         <p>
           最近到期日<span>2024-10-12 23:59</span>
         </p>
      </div>
    </div>
    <div class="pcTable">
       <div>
          <div class="pchead">
            <span>時間</span>
            <span>内容</span>
            <span>數量</span>
            <span>eSIM Coin 到期日</span>
          </div>
         <div v-if="pointList.length == 0">
         <div class="pcTableBody" v-for="(item,i) in 10" :key="i">
           <span>2022-10-12 13:30</span>
           <span>新會員歡迎送</span>
           <span :style="{color: -1> 0?'#CE1111':'#272727'}">500</span>
           <span>2024-10-12 23:59</span>
         </div>
         </div>
         <div class="wu" v-else>
           無獲得紀錄
         </div>
       </div>
    </div>
    <div class="phoneNav">
       <div class="navLeft">
          <span>100000</span>
          <p>可折抵 eSIM Coin<img src="@/assets/image/wen.png" alt="wen" @click="dialogTableVisible = true" /></p>
       </div>
      <div class="navRight">
        最近到期日<span>2024-10-12 23:59</span>
      </div>
    </div>
    <el-tabs class="phoneTab" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="全部記錄" name="first"></el-tab-pane>
      <el-tab-pane label="獲得記錄" name="second"></el-tab-pane>
      <el-tab-pane label="使用記錄" name="third"></el-tab-pane>
    </el-tabs>
    <div class="phoneTable">
       <div class="phoneList" v-for="(item,i) in 10" :key="i">
          <span>2022-10-12  13:30</span>
          <div class="nr">
             <div class="title">
               新會員歡迎送
             </div>
            <div class="shu">
              +500
            </div>
          </div>
         <p>點數到期日<span>2024-10-12 23:59</span></p>
       </div>
    </div>

    <el-dialog title="eSIM Coin 規則說明" center :visible.sync="dialogTableVisible" :close-on-click-modal="false" :show-close="false">
      <p>
        在eSImart消費時<span>折10 eSIM Coin 抵新台幣一元；</span></p>
      <p><span>折抵時將從即將到期之eSIM Coin開始折抵，不再另開收據。</span></p>
      <p>不同優惠活動回饋之eSIM Coin皆有使用效期:180天，有效期間屆滿，尚未使用的 eSIM Coin將於到期日23:59:59自動失效，不另行通知。</p>
      <p>若遇訂單取消或退貨/退款程序完成後，該筆訂單折抵的eSIM Coin，亦不返還</p>
      <p><span>eSIM Coin不可兌換成現金、轉換</span></p>
      <div class="coinBody">
          <p>獲得eSIM Coin 的方法：</p>
        <ul>
          <li>成功註冊會員：50 eSIM Coin</li>
          <li>推薦好友使用推薦碼註冊成功並完成下單：50 eSIM Coin</li>
          <li>使用好友推薦碼註冊會員成功：50 eSIM Coin</li>
          <li>留下使用評論心得：50 eSIM Coin （每日僅限一次）</li>
          <li>會員生日禮：50 eSIM Coin （當月一號匯入帳號）</li>
        </ul>
        <p>訂單滿額贈，單筆訂單滿NT$500贈10 eSIM Coin</p>
        <p>參加eSImart不定期舉辦的活動</p>
      </div>
      <p>感謝您對 eSImart 的支持與信任。提醒您，根據我們的服務條款，積分的使用權由 eSImart 全權酌情決定，消費者不得有異議。<br/>這是我們努力為您提供更好服務的一部分，同時也保障了我們的業務運作。
      <br/>我們非常感謝您對我們的理解和支持。</p>
      <p>如果您有任何疑問或需要協助，請隨時聯繫我們，我們將竭誠為您服務。</p>
      <div class="_btnn">
        <el-button @click="dialogTableVisible = false">關閉</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "points.vue",
  data() {
    return{
       state: 0,
       pointList: [],
       activeName: 'first',
      dialogTableVisible: false
    }
  },
  methods:{
    clickState(item) {
      this.activeName = item;
    },
    handleClick(){

    }
  }
}
</script>

<style>
body{
  margin: 8px 0 !important;
}
</style>

<style scoped lang="less">
.phoneTable{
  display: none;
}
.order-nav ul li{
  background: #EEEEEE !important;
  color: #666666 !important;
}
.order-nav .active,.order-nav ul li:hover{
  background: #00A2FD !important;
  color: #ffffff !important;
}
.poinTop{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .order-nav{
    margin: 0;
  }
  .top{
    position: relative;
    .card{
      background: url("../../assets/image/coin.png");
      background-size: cover;
      padding: 10px 20px;
      border-radius: 15px 15px 0 15px;
      text-align: end;
    }
    span:first-child{
      font-size: 18px;
      font-weight: 500;
      //line-height: 26.06px;
      text-align: left;
      color: #ffffff;
      margin-right: 20px;
    }
    span:nth-child(2){
      font-family: Noto Sans TC;
      font-size: 25px;
      font-weight: 700;
      //line-height: 36.2px;
      text-align: left;
    }
    p{
      position: absolute;
      right: 0;
      display: inline-block;
      font-family: Noto Sans TC;
      font-size: 16px;
      font-weight: 400;
      text-align: left;
      background: #EEEEEE;
      padding: 5px 20px;
      border-radius: 0 0 10px 10px;
      margin: 0;
      color: #272727;
      text-align: end;

      span{
        font-size: 14px !important;
        font-weight: 400;
        text-align: left;
        margin-left: 10px;
        color: #0B57C9 !important;
        margin-right: 0 !important;
      }
    }
  }
}

.pcTable{
  margin: 40px -10px 20px -10px;
   .pchead,.pcTableBody{
     display: flex;
     align-items: center;
     justify-content: space-between;
     background: #CFCFCF;
     padding:10px 40px;
     color: #666666;
     span{
       font-size: 18px;
       font-weight: 500;
       text-align: left;
     }
     span:nth-child(2){
       width: 200px;
     }
     span:nth-child(3){
       width: 140px;
     }
     span:nth-child(1),span:nth-child(4){
       width: 200px;
     }
   }
  .pcTableBody{
    color: #272727;
    background: transparent;
    border-bottom: 1px solid #D7D7D7;
    padding: 20px 40px;
  }
  .pcTableBody:last-child{
    border-bottom: none;
  }
  .wu{
    font-size: 32px;
    font-weight: 400;
    line-height: 46.34px;
    text-align: left;
    color: #D9D9D9;
    margin: 200px 0;
    text-align: center;

  }
}

::v-deep .el-dialog {
  width: 60%;
  border-radius: 10px;

  p {
    font-size: 18px;
    color: #000000;
    line-height: 26px;
  }

  ._btnn {
    margin-top: 30px;
    text-align: center;

    .el-button {
      color: #ffffff;
      padding: 10px 80px;
      border-radius: 20px;
      background: #130F26;
    }
  }

}

::v-deep .el-dialog--center .el-dialog__body {
  padding: 30px 48px 15px;
}

::v-deep .el-dialog__header {
  background: #2E2E2E;
  border-radius: 8px 8px 0 0;
  padding: 10px 0;
}

::v-deep .el-dialog__title {
  color: #ffffff;
  font-size: 22px;
  line-height: 22px;
}

::v-deep .el-dialog__headerbtn .el-dialog__close {
  color: #ffffff;
  font-size: 22px;
}

::v-deep .el-dialog__headerbtn {
  top: 10px;
}

.coinBody{
  padding: 25px 28px;
  border-radius: 10px;
  background: #EEEEEE;
  p{
    margin: 0 0 10px 0;
  }
  ul{
    margin: 0;
    padding: 0 0 0 28px;
    list-style: initial;
    li{
      font-size: 18px;
      color: #000000;
      line-height: 26px;
      margin-bottom: 10px;
    }
  }
}



@media screen and (max-width: 800px){
  ::v-deep .el-dialog{
    width: 90% !important;
  }
  .order-nav{
    background: transparent !important;
  }
  .pcTable,.poinTop{
    display: none;
  }
  .phoneNav{
     display: flex;
    gap: 20px;
     justify-content: space-between;
    align-items: center;
    background: url("../../assets/image/coin.png");
    background-size: cover;
    margin-top: 40px;
    padding: 10px 0 10px 20px;
    .navLeft{
       span{
         font-size: 45px;
         font-weight: 700;
         letter-spacing: 0.01em;
         text-align: left;
       }
      p{
        font-size: 12px;
        font-weight: 500;
        text-align: left;
        display: flex;
        align-items: center;
        gap: 10px;
        img{
          cursor: pointer;
        }
      }
    }
    .navRight{
      display: flex;
      align-items: center;
      white-space: nowrap;
      background: #FFFFFF;
      padding: 8px 19px;
      border-radius: 5px 0 0 5px;
      font-size: 12px;
      font-weight: 500;
      text-align: left;
      color: #272727;
      gap: 10px;
    }
  }
  .phoneTab{
     margin-top: 30px;
    padding: 0 20px;
   ::v-deep .el-tabs__item{
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.04em;
      text-align: left;
     color: #B6B6B6;
     width: 33.33%;
     text-align: center;
    }
    ::v-deep .el-tabs__item.is-active{
      color: #00A2FD !important;
    }
    ::v-deep .el-tabs__nav{
      width: 100%;
    }
  }
  .phoneTable{
    display: block;
    margin: 20px;
    .phoneList{
      padding: 12px 22px;
      border-radius: 5px;
      background: #ffffff;
      margin-bottom: 10px;
      span{
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        color: #B6B6B6;
      }
     .nr{
        display: flex;
       align-items: center;
       justify-content: space-between;
       margin: 5px 0;
       .title{
         font-size: 18px;
         font-weight: 400;
         text-align: left;
         color: #272727;
       }
       .shu{
         font-family: Noto Sans TC;
         font-size: 18px;
         font-weight: 500;
         text-align: right;
         color: #00A2FD;
       }
      }
      p{
        font-size: 12px;
        font-weight: 400;
        text-align: left;
        color: #B6B6B6;
        span{
          padding-left: 5px;
        }
      }
    }
  }

}
</style>
